<template>
  <div :class="`${teamsContext && teamsContext.tid ? 'teams-app--container' : 'teams-app--setup'} ${teamsContext && teamsContext.theme ? 'teams-app--theme-' + teamsContext.theme : 'teams-app--theme-default'}`">
    <!-- Microsoft Teams JavaScript API (via CDN) -->
    <script type="application/javascript" @load="teamsLoaded" src="https://statics.teams.cdn.office.net/sdk/v1.11.0/js/MicrosoftTeams.min.js" defer async integrity="sha384-SCVF3m7OvDKnfAilUzYn2yozEvBeP8n/Oq0yTH8VUI70J4AzrqR70jzjdQ6DI8s2" crossorigin="anonymous"></script>
    <!-- <script type="application/javascript" @load="teamsLoaded" src="https://res.cdn.office.net/teams-js/2.21.0/js/MicrosoftTeams.min.js"       defer async integrity="sha384-8IiFmUTxC/bV/D3X+IBPbfibh4oRJIbu7E14dqbrDjvMRs5JDg7p4sz0/JT74f2a" crossorigin="anonymous"></script> -->
    <router-view :microsoftTeams="microsoftTeams" :teamsContext="teamsContext"></router-view>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { EventBus } from '@/plugins/eventbus.js';
import accountService from "@/services/accountService";
import tenants from '@/data/tenants.config'
import i18n from '@/i18n'
// https://docs.microsoft.com/en-us/javascript/api/overview/msteams-client?view=msteams-client-js-latest
//import * as microsoftTeams from "@microsoft/teams-js";
const tenant = tenants.current();
//const microsoftTeams = window.microsoftTeams;

export default {
  components: {
  },
  props: {
  },
  data: () => ({
    microsoftTeams: null,
    teamsContext: null,
  }),

  async mounted() {
    // disable all layout
    this.$store.state.view = 'app';
    this.$store.state.context = 'microsoft-teams';

    EventBus.$on('login-state-change', async user => {
      if (user) {
        console.log('Authed');
      }
    });
    if (this.$route.query.fake === 'channel') {
      this.$nextTick(() => {
        this.teamsContext = {
          tid: '5d13c221-17e7-43a7-947f-7af95a0da1ca',
          teamId: '19:bb05826c30ab4834bd0405f490740715@thread.tacv2',
          groupId: 'bdcf6fc1-b946-421d-b505-f7f38f4dd289',
          channelId: '19:dce8963aa8374e13aa15b69b46029027@thread.tacv2',
          //userObjectId: 'ae6c127e-feb1-4d83-bcea-a9ca66ac0b2d',
          entityId: 'koens-multi-team-event',
          theme: 'default', // default | dark | contrast
          //hostClientType: 'ios',
          //subEntityId: 'koens-teams-test-event-12_steppin',
        };
      });
    }
    else if (this.$route.query.fake === 'result') {
      this.$nextTick(() => {
        this.teamsContext = {
          tid: '5d13c221-17e7-43a7-947f-7af95a0da1ca',
          teamId: '19:bb05826c30ab4834bd0405f490740715@thread.tacv2',
          groupId: 'bdcf6fc1-b946-421d-b505-f7f38f4dd289',
          channelId: '19:dce8963aa8374e13aa15b69b46029027@thread.tacv2',
          entityId: 'result:koens-custom-teams:5k-teams:g_koens-custom-teams-us',
          theme: 'default', // default | dark | contrast
          //hostClientType: 'ios',
        };
      });
    }
  },

  methods: {
    async teamsLoaded() {
      console.log('Hi loaded Teams?!', window.microsoftTeams);
      window.microsoftTeams.initialize();
      this.microsoftTeams = window.microsoftTeams;
      let self = this;
      microsoftTeams.getContext(function(context) { /* ... */ 
        console.log('Teams context:', context);
        self.teamsContext = context;
        const eventId = context.subEntityId;
        const tenantId = context.tid;
        const userId = context.userObjectId;
        const chatId = context.chatId;
        const channelId = context.channelId;
        const teamId = context.teamId;
/* 
        if (eventId) {
          microsoftTeams.conversations.openConversation({
            title: "CorporateFitness.app Bot", 
            channelId: context.channelId, 
            entityId: context.entityId, 
            subEntityId: context.subEntityId, 
          });
        } */
      });
      this.microsoftTeams.appInitialization.notifyAppLoaded(); // when content is visible
      this.microsoftTeams.appInitialization.notifySuccess(); // when all content is loaded


      // this.microsoftTeams.conversations.onStartConversation = (conversationResponse) => {
      //   console.log('onStartConversation', conversationResponse.conversationId, conversationResponse);
      // };
    },
  },
  computed: {

  },
};
</script>
<style lang="scss" >
  #content { min-height: inherit !important;}
  .teams-app--setup {
    background-color: #fff;
  }
  .teams-app--container {
    background-color: rgb(245, 245, 245);
  }


  .teams-app--theme-default {

  }

  .teams-app--theme-dark {
    &.teams-app--setup {
      background-color: #000;
    }
    &.teams-app--container {
      background-color: rgb(31, 31, 31);
    }

    .v-card {
      background-color: rgb(41, 41, 41);
    }

  }

  .teams-app--theme-contrast {
    
  }
</style>

